import React, {Component} from 'react'
import {Fragment} from 'react';
import loginLogo from './../assets/images/logo-iona.png';
import logo from './logo.svg'
import './SignIn.css'
import {connect} from 'react-redux'
import cognitoUtils from '../lib/cognitoUtils'
import {Card, Button} from 'react-bootstrap';
import "./SignIn.css";
import {deleteCookie, getCookie, setCookie} from "../services/cookie";

const mapStateToProps = state => {
    return {session: state.session}
}

class SignIn extends Component {
    constructor(props) {
        super(props)
        this.state = {apiStatus: 'Not called', session: {}}
    }

    componentDidMount() {
        let session = getCookie("session");
        if (session) {
            let data = JSON.parse(session);
            data.isLoggedIn = true;
            this.setState({
                session: data
            });
        }
    }

    onSignOut = (e) => {
        e.preventDefault()
        cognitoUtils.signOutCognitoSession()
        deleteCookie("session");
    }

    render() {
        return (
            <Fragment>
                <div className="ssologin-card-container">
                    <Card className="text-center ssologin-card-box-shadow ssologin-card-box-dimensions">
                        <Card.Img className="ssologin-img-logo" variant="top" src={loginLogo}/>
                        <Card.Body className="ssologin-card-body">
                            <Card.Title>
                                {"IONA SINGLE SIGN ON"}
                            </Card.Title>
                            {this.state.session.isLoggedIn ? (
                                <div>
                                    <p>You are logged in as
                                        user {this.state.session.user.userName} ({this.state.session.user.email}).</p>
                                    <p></p>
                                    <p></p>
                                    <Button variant="primary" onClick={this.onSignOut}>{"Sign Out"}</Button>
                                </div>
                            ) : (
                                <div>
                                    <p>You are not logged in.</p>
                                    <Button variant="primary"
                                            className="ssologin-button-text ssologin-button-responsive"
                                            href={cognitoUtils.getCognitoSignInUri()}>Sign in</Button>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </div>
            </Fragment>
        );
    }

    render2() {
        return (
            <div className="Home">
                <header className="Home-header">
                    <img src={logo} className="Home-logo" alt="logo"/>
                    {this.props.session.isLoggedIn ? (
                        <div>
                            <p>You are logged in as
                                user {this.props.session.user.userName} ({this.props.session.user.email}).</p>
                            <p></p>
                            <div>
                                <div>API status: {this.state.apiStatus}</div>
                                <div className="Home-api-response">{this.state.apiResponse}</div>
                            </div>
                            <p></p>
                            <a className="Home-link" href="#" onClick={this.onSignOut}>Sign out</a>
                        </div>
                    ) : (
                        <div>
                            <p>You are not logged in.</p>
                            <a className="Home-link" href={cognitoUtils.getCognitoSignInUri()}>Sign in</a>
                        </div>
                    )}
                </header>
            </div>
        )
    }
}

export default connect(mapStateToProps)(SignIn)
