import axios from 'axios';
import * as utils from './cookie';
import { API_CONFIG } from './config';

export function fetch(pathParam, headers=null, responseType) {
    let session = utils.getCookie("session");
    let AccessToken = JSON.parse(session).credentials.accessToken;
    if(AccessToken){
        headers = { 'Cache-Control': 'no-cache', "authorization": "Bearer " + AccessToken };
    }
   
    if(responseType) {
        return axios
        .get(API_CONFIG.baseUrl + pathParam, { headers,  responseType:'arraybuffer' } );
    } else {
    return axios
        .get(API_CONFIG.baseUrl + pathParam, { headers });
    }

}

export function store(pathParam, data, headers=null, isdownload ) {
    let session = utils.getCookie("session");
    let AccessToken = JSON.parse(session).credentials.accessToken;
    if(AccessToken){
        headers = { 'Cache-Control': 'no-cache', "authorization": "Bearer " + AccessToken };
    }
 
    if(isdownload) {
        return axios
        .post(API_CONFIG.baseUrl + pathParam, data, { headers,  responseType:'arraybuffer' });

    } else {

        return axios
        .post(API_CONFIG.baseUrl + pathParam, data, { headers });   
    }
}

export function update(pathParam, data, headers) {
    return axios
        .put(API_CONFIG.baseUrl + pathParam, data, { headers });
}

export function patch(pathParam, data, headers) {
    return axios
        .patch(API_CONFIG.baseUrl + pathParam, data, { headers });
}

// export function getWithParem(pathParam, data , headers) {
//     return axios.get(API_CONFIG.baseUrl + pathParam, {
//         params: data
//     }, headers)
// }

export function destroy(pathParam, headers) {
    return axios
        .delete(API_CONFIG.baseUrl + pathParam, { headers });
}


export function storeWithouHeader(pathParam, data) {
    return axios
        .post(API_CONFIG.baseUrl + pathParam, data);
}

export function fetchWithoutHeader(pathParam) {
    return axios
        .get(API_CONFIG.baseUrl + pathParam);
}

export function fileUpload(url, formValue) {
    var options = {
        headers: {
            'Content-Type': "multipart/form-data",
            'Authorization': `Bearer ${utils.getCookie('idToken')}`,
        }
    };

    return axios
        .put(API_CONFIG.baseUrl + url, formValue, options);
}

export function formPost(url, formValue) {
    var options = {
        headers: {
            'Content-Type': "multipart/form-data",
        }
    };
    var formData = new FormData();

    for (var key in formValue) {
        formData.append(key, formValue[key]);
    }

    return axios
        .post(API_CONFIG.baseUrl + url, formData, options);
}