import * as React from 'react';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './dashboard/Title';
import {fetch, store} from "../services/api";
import {useEffect} from "react";
import {Checkbox, FormControlLabel, FormGroup} from "@material-ui/core";

function preventDefault(event) {
    event.preventDefault();
}

export default function SettingGrid() {
    const [services, setServices] = React.useState(null);
    const [value, setValue]=React.useState(null);
    const fetchServices = async () => {
        let docs = await fetch("vendors");
        console.log("got the documents", docs);
        if (docs.data && docs.data.length == 1) {
            setServices(docs.data[0].services);
        } else {
            setServices([]);
        }
    }

    const handleChange = async(event, row) => {
        console.log("changed value ", event.target.checked);
        let body ={
            checkId: row.checkId,
            checkName: row.checkName,
            isChecked: event.target.checked
        }
        row.isChecked = event.target.checked;
        setValue(row.isChecked);
        await store("vendors", body);
    };

    useEffect(() => {
        if (services == null) {
            fetchServices();
        }
    },[value])

    return (
        <React.Fragment>
            <Title>Vendor Services</Title>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Service Name</TableCell>
                        <TableCell>Check ID </TableCell>
                        <TableCell>Verify By IONA</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {services && services.map((row) => (
                        <TableRow key={row.checkId+ "" + row.checkName}>
                            <TableCell>{row.checkName}</TableCell>
                            <TableCell>{row.checkId}</TableCell>
                            <TableCell>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={row.isChecked} onChange={(event)=> handleChange(event, row)}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label=""
                                    />
                                </FormGroup></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Link color="primary" href="#" onClick={preventDefault} sx={{mt: 3}}>
                {/*See more orders*/}
            </Link>
        </React.Fragment>
    );
}
