import React, {Component} from 'react'
import { Fragment} from 'react';
import loginlogo from './../assets/images/logo-iona.png';
import logo from './logo.svg'
import './SignIn.css'
import {connect} from 'react-redux'
import cognitoUtils from '../lib/cognitoUtils'
import {Card, Button} from 'react-bootstrap';
import "./SignIn.css";
import {deleteCookie, getCookie, setCookie} from "../services/cookie";
import {Redirect} from "react-router-dom";

const mapStateToProps = state => {
    return {session: state.session}
}

class SignIn extends Component {
    constructor(props) {
        super(props)
        this.state = {apiStatus: 'Not called', redirectToLogin: false, session: {}}
    }

    componentDidMount() {
        if (this.props.session.isLoggedIn) {
            if(this.props.session){
                this.setState({
                    session: this.props.session
                });
            }
            setCookie("session", JSON.stringify(this.props.session));
        } else {
            let session = getCookie("session");
            console.log("session from cookie", session);
            if (session) {
                this.setState({
                    session: JSON.parse(session)
                });
            } else {
                this.setState({
                    redirectToLogin: true
                });
            }
        }
    }

    onSignOut = (e) => {
        e.preventDefault()
        cognitoUtils.signOutCognitoSession();
        deleteCookie("session");
    }

    render() {
        if (this.state.redirectToLogin) {
            return <Redirect to="/"/>
        }
        return (
            <Fragment>
                <div className="ssologin-card-container">
                    <Card className="text-center ssologin-card-box-shadow ssologin-card-box-dimensions">
                        <Card.Img className="ssologin-img-logo" variant="top" src={loginlogo}/>
                        <Card.Body className="ssologin-card-body">
                            <Card.Title>
                                {"IONA SINGLE SIGN ON"}
                            </Card.Title>
                            {this.state.session.isLoggedIn ? (
                                <div>
                                    <p>You are logged in as
                                        user {this.state.session.user.userName} ({this.state.session.user.email}).</p>
                                    <p></p>
                                    <div>
                                        {"Home Page"}
                                    </div>
                                    <p></p>
                                    <Button variant="primary" onClick={this.onSignOut}>{"Sign Out"}</Button>
                                </div>
                            ) : (
                                <div>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </div>
            </Fragment>
        );
    }
}

export default connect(mapStateToProps)(SignIn)
