import React, { useEffect } from 'react';
import Card from '@material-ui/core/Card';

import CardContent from '@material-ui/core/CardContent';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from '@material-ui/core/Tooltip';

import LinkIcon from '@material-ui/icons/Link';
import Link from "@material-ui/core/Link";
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';
import { DialogActions, DialogContent, DialogContentText, Pagination, TextField } from "@material-ui/core";
import { fetch, store } from "../../services/api";

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { getCookie } from "../../services/cookie";

function SimpleDialog(props) {
    const { onClose, selectedValue, open, checkRow } = props;
    const [comment, setComment] = React.useState("");
    const handleClose = (save) => {
        onClose(comment, save, checkRow);
        setComment("");
    };

    return (
        <Dialog wi onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Add your comment</DialogTitle>
            <DialogContent>
                <DialogContentText style={{ width: "500px" }}>
                    {/* To subscribe the verification status */}
                </DialogContentText>
                <TextField
                    autoFocus
                    value={comment}
                    onChange={(value) => setComment(value.target.value)}
                    margin="dense"
                    id="name"
                    label="Comment"
                    type="multiline"
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => handleClose(true)} color="primary">
                    Verify
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default function TreeDocument() {
    const [page, setPage] = React.useState(1);
    const [searchText, setSearchText] = React.useState("");
    const [documents, setDocuments] = React.useState(null);
    const [totalPageCount, setTotalPageCount] = React.useState(1);

    const [selectedCandidate, setSelectedCandidate] = React.useState(null);

    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(null);

    const handleClose = async (comments, save, checkRow) => {
        if (save) {
            console.log("selected check value ", selectedValue);
            await updateCheckStatus(selectedValue.candidate, selectedValue.type, selectedValue.status, comments, selectedValue.checkUID);
        }
        setSelectedValue(null);
        setOpen(false);
    };

    const handleClickOpen = (candidate, type, status, checkData) => {
        setOpen(true);
        setSelectedValue({ candidate: candidate, type: type, status: status, checkUID: checkData.checkUID })
    }


    const fetchDocuments = async (candidate, searchString) => {
        let searchUrl
        if (searchText) {
            searchUrl = "documents?pageNumber=" + page + "&pageSize=20" + "&searchString=" + searchText;
        } else if (searchString) {
            searchUrl = "documents?pageNumber=" + page + "&pageSize=20" + "&searchString=" + searchString;
        } else {
            searchUrl = "documents?pageNumber=" + page + "&pageSize=20";
        }

        let docs = await fetch(searchUrl);
        if (docs.data) {
            setDocuments(docs.data.documents);
            setTotalPageCount(Math.ceil(docs.data.count / 20));
            if (candidate) {
                let didWeFind = docs.data.documents.find(x => x._id == candidate._id);
                if (didWeFind) {
                    setSelectedCandidate(didWeFind);
                } else {
                    setSelectedCandidate(docs.data.documents[0]);
                }
            } else {
                setSelectedCandidate(docs.data.documents[0]);
            }
        } else {
            setDocuments([]);
        }
    }


    const searchList = (e) => {
        console.log('e', e);
        setSearchText(e);
        fetchDocuments(null, e)
    }
    const updateCheckStatus = async (candidate, type, status, comments, checkUID) => {
        let name = getCookie("userName");
        console.log("check UID ", checkUID);
        let response = await store("check", {
            candidateId: candidate._id,
            type: type,
            status: status,
            comments: comments,
            verifiedBy: name,
            checkUID: checkUID
        });

        console.log("response", response);
        if (response.status == 200) {

            fetchDocuments(selectedCandidate);
        }

        setOpen(true);

    }


    useEffect(() => {
        console.log("calling use effect");
        if (documents == null) {
            fetchDocuments();
        }
    }, [page])

    const handlePageChange = (event, value) => {
        setDocuments(null);
        setPage(value);
    };
    const onCandidateChange = (value) => {
        setSelectedCandidate(value);
    };

    const getCheckClass = (value) => {
        let clName = ''
        clName = (value + 1) % 2 == 0 ? 'bg-light mb-3 p-3' : 'mb-3 p-3';
        return clName
    }
    return (
        <>

            <div>
                <div className="row">
                    <div className='col-sm-12 mb-3'>
                        {/* onChange={(a) => this.changeReportingManager(a, 'reportingManager')} */}
                        <div>
                            <TextField id="search" label="search" variant="outlined"
                                size="small" fullWidth onChange={(e) => searchList(e.target.value)} />
                        </div>
                    </div>

                    <div className="col-sm-4">


                        {documents && documents.map((row) => (
                            //   <div className= my-3">

                            //   </div>
                            <Card key={row}
                                className={selectedCandidate && (row._id == selectedCandidate._id) ? 'bg-light shadow-sm mb-3 border' : 'mb-3 border shadow-none'}
                                onClick={() => onCandidateChange(row)}>
                                <CardContent>
                                    <Typography>
                                        {row.name}
                                    </Typography>
                                    <Typography variant="body2" component="p" color="textSecondary">
                                        {row.email}
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))}

                    </div>

                    <div className="col-sm-8">
                        {/* { selectedCandidate && selectedCandidate.requestedChecks.map((historyRow) => (
                    ))
                    } */}
                        {selectedCandidate && selectedCandidate.requestedChecks && selectedCandidate.requestedChecks.filter(x => x.isReadyToCheck || x.status == 'Disabled').map((historyRow, index) => (
                            <div className="mb-4" key={index}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        className="bg-light shadow-sm"
                                        id="panel1a-header"
                                    >
                                        <div className="d-flex w-100 align-items-start">
                                            <Typography> {historyRow.checkName ? historyRow.checkName : historyRow.sourceVerification}</Typography>
                                            {/* ({historyRow.checkUID}) */}
                                            {historyRow.isChecked == true ? <>
                                                {historyRow.status != 'Completed' ?
                                                    <><span className=""
                                                        style={{ marginLeft: "30px" }}>{historyRow.files.length > 0 &&
                                                            historyRow.files.map((file, index) =>
                                                                <Link target="_blank" color="primary" key={index}
                                                                    href={historyRow.files[index].fileContent}
                                                                    sx={{ mt: 3 }}>
                                                                    <LinkIcon></LinkIcon>
                                                                </Link>
                                                            )
                                                        }</span>
                                                        <div className="ml-auto">

                                                            <a className="btn bg-success rounded-pill text-white px-4 badge py-2"
                                                                onClick={() => handleClickOpen(selectedCandidate, historyRow.type, "Completed", historyRow)}
                                                                aria-label="Approve"
                                                                size="small">
                                                                Portal Verify
                                                            </a>
                                                            <SimpleDialog checkRow={historyRow}
                                                                selectedValue={selectedValue} open={open}
                                                                onClose={handleClose} />
                                                        </div>
                                                    </> : <span
                                                        className="badge badge-success ml-auto py-2 px-3 rounded-pill">{historyRow.status}</span>
                                                }
                                            </> : <>
                                                <span className=""
                                                    style={{ marginLeft: "30px" }}>{historyRow.files.length > 0 &&
                                                        historyRow.files.map((file, index) =>
                                                            <Link target="_blank" color="primary" key={index}
                                                                href={historyRow.files[index].fileContent}
                                                                sx={{ mt: 3 }}>
                                                                <LinkIcon></LinkIcon>
                                                            </Link>
                                                        )
                                                    }</span>
                                                <Tooltip title={!historyRow.status ? "" : historyRow.status} aria-label="add" placement="left-start">
                                                    <span className="badge badge-primary ml-auto py-2 px-3 rounded-pill">
                                                        {
                                                            historyRow.isChecked != true && !historyRow.status ?
                                                                <ErrorOutlineIcon></ErrorOutlineIcon> :

                                                                historyRow.status && historyRow.status == 'Completed' ?
                                                                    <DoneAllIcon></DoneAllIcon>
                                                                    : historyRow.status && historyRow.status == 'WorkInProgress' ?
                                                                        <HourglassEmptyIcon></HourglassEmptyIcon> :
                                                                        <SyncProblemIcon></SyncProblemIcon>
                                                        }
                                                        <span className="ml-1"> Auth Bridge</span></span>
                                                </Tooltip>
                                            </>}
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <div className="bg-light mb-3 p-3">
                                                <div>Source Address:    {historyRow.sourceAddress ? historyRow.sourceAddress : "NA"}</div>
                                                <div>Source Verification:   {historyRow.sourceVerification ? historyRow.sourceVerification : "NA"}</div>
                                            </div>
                                            {historyRow.checkFields.map((checkField, index) => (
                                                <div key={checkField.key} className={getCheckClass(index)}>
                                                    <div>{checkField.name}: {checkField.value ?
                                                        checkField.value :
                                                        "NA"}</div>
                                                    {/* 
                                                    <div>
                                                        Status: {historyRow.status ? historyRow.status : historyRow.isChecked != true ? "Not Sent" : ""}
                                                    </div> */}
                                                </div>
                                            ))}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            // <div className='card p-2 mb-3' key={index}>

                            //     <div className="d-flex align-items-center">
                            //         {historyRow.sourceVerification}({historyRow.checkUID})
                            //         <div className="d-flex ml-auto">
                            //             { historyRow.isChecked == true ? <>
                            //                 {historyRow.status != 'Completed' ?
                            //                     <div className="mt-2">
                            //                         <a className="btn bg-success rounded-pill text-white px-4 badge py-2"
                            //                            onClick={() => handleClickOpen(selectedCandidate, historyRow.type, "Completed")}
                            //                            aria-label="Approve"
                            //                            size="small">
                            //                             Verify
                            //                         </a>
                            //                         <SimpleDialog selectedValue={selectedValue} open={open}
                            //                                       onClose={handleClose}/>
                            //                     </div> : <span
                            //                         className="badge badge-success ml-auto py-2 px-3 rounded-pill">{historyRow.status}</span>
                            //                 }

                            //             </> : <span className="badge badge-primary ml-auto py-2 px-3 rounded-pill"> Auth Bridge</span>}
                            //         </div>
                            //     </div>

                            //     <div>

                            //         {historyRow.checkFields.map((checkField) => (
                            //             <div key={checkField.key}>


                            //                 <div>{checkField.name}: {checkField.value ?
                            //                     checkField.value :
                            //                     "NA"}</div>

                            //                 <div>
                            //                     Status: {historyRow.status}
                            //                 </div>
                            //                 <div>
                            //                     {historyRow.files.length > 0 &&
                            //                     <Link target="_blank" color="primary"
                            //                           href={historyRow.files[0].fileContent}
                            //                           sx={{mt: 3}}>
                            //                         <LinkIcon></LinkIcon>
                            //                     </Link>
                            //                     }
                            //                 </div>
                            //             </div>
                            //         ))}
                            //     </div>


                            // </div>

                        ))}

                    </div>
                </div>
            </div>

            <div>
                <Typography>Page: {page}</Typography>
                <Pagination count={totalPageCount} page={page} onChange={handlePageChange} />
            </div>
        </>
    );
}
