import React from 'react'
import {
  Router,
  Route
} from 'react-router-dom'
import Callback from './routes/Callback'
import SignIn from './routes/SignIn'
import "./assets/vendor/bootstrap.min.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/style.css";
import "./assets/css/styles.scss";
import './App.css';

import { createBrowserHistory } from 'history'
import Home from "./routes/Home";
import Dashboard from "./components/dashboard/Dashboard";
import Settings from "./components/Settings";

const history = createBrowserHistory()

const App = () => (
  <Router history={history}>
    <Route exact path="/" component={SignIn}/>
    <Route exact path="/callback" component={Callback}/>
      <Route exact path="/Home" component={Dashboard}/>
      <Route exact path="/Dashboard" component={Dashboard}/>
      <Route exact path="/Setting" component={Settings}/>
  </Router>
)

export default App
