export function setCookie(name, value, expirationDays) {
    // console.log(name, value, "name, value")
    sessionStorage.setItem(name, value);
    return
}

export function deleteCookie(name) {
    sessionStorage.removeItem(name);
    return
}

export function getCookie(name) {
    return sessionStorage.getItem(name);
}